<template>
  <div class="products-view__items">
    <div
      v-for="product in products"
      :key="product.id"
      class="product"
      :class="{ 'product_chosen': product.isUsed }"
      @click.self="selectProduct(product)"
    >
      <div class="product__name" @click.self="selectProduct(product)">
        {{ getName(product) }}
      </div>
      <img :src="product.img" alt="" class="product__img" @click.self="selectProduct(product)" />
      <div class="product__controls">
        <SimpleButton
          color="default"
          font="minor"
          @clickedFromSimpleBtn="selectProduct(product)"
          additionalClass="cog"
        >
          <template #icon>
            <img src="@/assets/img/icons/cog--red.svg" alt="" class="icon" />
          </template>
          {{ $t(`message.buttons.customize.${placement}`) }}
        </SimpleButton>
        <tippy
          v-if="(product.text !== '' || product.url) && !isGroup(product)"
          interactive
          placement="right-start"
          :maxWidth="308"
        >
          <template #trigger>
            <div class="product__controls-info">
              <img src="@/assets/img/icons/product--info.svg" alt="i" />
            </div>
          </template>
          <span class="product-info__tooltip">
            <span class="product-info__tooltip-text" v-if="product.text !== ''">
              {{ product.text }}
            </span>
            <a
              :href="product.url"
              target="_blank"
              class="product-info__tooltip-link"
              v-if="product.url && product.url !== ''"
              :class="{ mt: product.text !== '' }"
            >
              <img src="@/assets/img/icons/planet.svg" alt="" />
              {{ $t('message.systems.more') }}
            </a>
          </span>
        </tippy>
      </div>
      <div class="modal-trigger" v-if="!isDesktop" @click.self="showMobileModal(product)"></div>
    </div>
    <button class="create-btn" :class="{ 'small': products.length % 3 !== 0 }" @click="createCustom()">
      <slot name="image"></slot>
      <slot></slot>
    </button>
    <Modal :isModalOpen="isModalOpen" :isShowClose="true" @close="close">
      <template #body>
        <Mobile-product-controls
          :product="currentProduct"
          :placement="placement"
          @selectFromMobileModal="selectProduct"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import SimpleButton from '@/components/elements/Dom/Simple-button'
import Modal from '@/components/elements/Modals/Modal'
import MobileProductControls from '@/components/elements/Modals/MobileProductControls'
import device from 'current-device'

export default {
  props: {
    products: Array,
    placement: {
      type: String,
      default: 'system',
      required: true
    }
  },
  data: () => ({
    // showInfo: '',
    isModalOpen: false,
    currentProduct: {},
    isDesktop: false
  }),
  components: {
    SimpleButton,
    MobileProductControls,
    Modal
  },
  methods: {
    selectProduct(product) {
      this.close()
      if (!this.isGroup(product) && !this.hasVariables(product)) {
        this.$emit('productSelected', product.id, null)
        this.$root.onBodyScroll()
      } else {
        this.$emit('showGroupModal', product)
      }
    },
    showMobileModal(product) {
      if (!this.isGroup(product) && !this.hasVariables(product)) {
        this.currentProduct = product
        this.isModalOpen = true
      } else {
        this.$emit('showGroupModal', product)
      }
    },
    close() {
      this.isModalOpen = false
    },
    createCustom() {
      this.$emit('createCustom')
    },
    getName(product) {
      return Object.prototype.hasOwnProperty.call(product, 'items')
        ? product.groupName
        : product.name
    },
    isGroup(product) {
      return Object.prototype.hasOwnProperty.call(product, 'items')
    },
    hasVariables(product) {
      return Object.prototype.hasOwnProperty.call(product, 'variables')
    }
  },
  mounted() {
    device.desktop() ? (this.isDesktop = true) : (this.isDesktop = false)
  }
}
</script>
<style lang="sass">
.product-info__tooltip
  padding: rem(11)
  text-align: left
  display: block
  @extend .fs-12
  color: #fff
  &-link
    color: #fff
    display: flex
    font-weight: bold
    text-decoration: underline
    &.mt
      margin-top: rem(12)
    img
      margin-right: rem(12)
</style>
<style scoped lang="sass">
.products
  &-view
    &__items
      +media((margin: (320: rem(24) auto rem(48), 960: rem(32) auto rem(48))))
      display: grid
      +media((grid-template-columns: (320: 1fr 1fr, 700: repeat(3, 1fr))))
      +media((max-width: (320: 92%, 960: 100%)))
      margin: auto
      .product
        cursor: pointer
        background: #FFFFFF
        border: rem(1) solid #ECECEC
        padding: rem(8)
        position: relative
        display: flex
        // display: flex
        flex-direction: column
        &_chosen
          filter: opacity(0.4)
        &__controls
          position: absolute
          top: 60%
          left: 50%
          transform: translate(-50%, -50%)
          z-index: 1
          opacity: 0
          pointer-events: none
          transition: cubic-bezier(0, 0, 0, 1.35) .3s
          flex-direction: row
          align-items: center
          width: 100%
          justify-content: center
          +media((display: (320: none, 960: flex)))
          &-info
            cursor: help
            line-height: 0
            margin-left: rem(4)
            transition: .5s
            height: rem(56)
            width: rem(40)
            background: rgba(246, 246, 246, 0.2)
            // box-shadow: 0 rem(5) rem(20) rgba(51, 51, 51, 0.15), 0 rem(1) rem(4) rgba(51, 51, 51, 0.4)
            border-radius: rem(4)
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            &:hover
              background: rgba(246, 246, 246, 0.5)
            input[type="radio"]
              position: absolute
              left: 0
              top: 0
              height: 0
              width: 0
              display: none
              opacity: 0
              pointer-events: none
        .simple-btn
          box-shadow: 0 rem(5) rem(20) rgba(51, 51, 51, 0.15), 0 rem(1) rem(4) rgba(51, 51, 51, 0.4)
          font-weight: 500
          padding: 0 rem(16)
        .info
          background: #FFFFFF
          box-shadow: 0 rem(4) rem(14) rgba(0, 0, 0, 0.16)
          border-radius: rem(4)
          +media((padding: (320: rem(17) rem(20), 992: rem(20) rem(20) rem(14))))
          display: flex
          flex-direction: column
          align-items: flex-start
          &__overlay
            z-index: 3
            +media((position: (320: fixed, 900: absolute)))
            +media((min-height: (320: 100vh, 900: 100%)))
            display: flex
            flex-direction: row
            justify-content: center
            align-items: center
            width: 100%
            top: 0
            left: 0
            transition: .5s
            +media((background: (320: rgba(0, 0, 0, 0.3), 900: transparent)))
            .info
              +media((min-height: (320: unset, 992: 100%)))
              +media((width: (320: rem(240), 992: 100%)))
              position: relative
        @media (any-hover: hover)
          &:hover
            .product
              &__name
                color: #fff
                &-btn
                  svg
                    path
                      fill: #fff
            .product__controls
              opacity: 1
              top: 50%
              pointer-events: auto
            &:after
              opacity: 1
        &:after
          content: ""
          height: 100%
          width: 100%
          position: absolute
          left: 0
          top: 0
          background: rgba(227, 7, 19, 0.9)
          transition: .5s
          opacity: 0
          pointer-events: none
          background-blend-mode: normal, color
        &__name
          z-index: 1
          position: relative
          font-style: normal
          font-weight: bold
          +media((font-size: (320: rem(11), 360: rem(12), 960: rem(14))))
          +media((line-height: (320: 150%, 960: 140%)))
          color: $black
          transition: .5s
          +media((padding: (320: rem(8) rem(8) rem(18), 960: rem(8) rem(12) rem(16))))
        &__img
          max-width: 100%
          width: auto
          +media((min-height: (320: unset, 768: rem(150))))
          height: auto
          margin: auto auto 0
          display: block
        .modal-trigger
          position: absolute
          height: 100%
          width: 100%
          background: transparent
          left: 0
          top: 0
          z-index: 4
          opacity: .3
          // +media((display: (320: block, 960: none)))
      .create-btn
        box-shadow: none
        outline: none
        background: $green
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        cursor: pointer
        transition: .5s
        border: rem(1) solid #ECECEC
        +media((min-height: (320: rem(150), 700: rem(200), 960: rem(304))))
        &.small
          +media((min-height: (320: rem(150), 700: auto)))
        img
          +media((width: (320: rem(70), 768: auto)))
        &:hover
          background: $green-hover
        &__text
          font-family: $main-font
          font-style: normal
          font-weight: bold
          +media((font-size: (320: rem(12), 960: rem(14))))
          +media((line-heigth: (320: 150%, 960: 140%)))
          text-align: center
          color: #fff
          +media((padding-top: (320: rem(8), 960: rem(28))))
</style>
