<template>
  <span :class="wrapperClasses">
    <input
      @input="handle"
      v-model="text"
      :type="type"
      :id="`input--${field.name}`"
      :placeholder="placeholder"
      class="input"
    />
    <button v-if="isSearch" type="button" class="search-clear" :class="{ 'visible': isDirty }" @click="clearSearch"></button>
    <span class="form-item__errors" v-if="isError">
      <slot name="errors" />
    </span>
  </span>
</template>

<script>
export default {
  name: 'AppInput',
  props: {
    field: {
      type: Object,
      default: () => ({})
    },
    isError: {
      type: Boolean
    },
    type: {
      type: String,
      default: 'text'
    },
    icon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text: ''
    }
  },
  methods: {
    handle() {
      this.$emit('input', this.text)
    },
    clearSearch() {
      this.text = ''
      this.handle()
    }
  },
  computed: {
    isSearch() {
      return this.type === 'search'
    },
    isDirty() {
      return this.text !== ''
    },
    wrapperClasses() {
      return {
        'form-item': true,
        'form-item--error': this.isError,
        'form-item--search': this.type === 'search',
        'form-item--icon': this.icon
      }
    },
    placeholder() {
      const placeholders = {
        'search-system': this.$t('message.placeholders.search-system'),
        'login-email': 'E-mail',
        'login-password': this.$t('message.placeholders.password'),
        'search-junction': this.$t('message.placeholders.search-junction')
      }
      return placeholders[this.field.name] || ''
    }
  }
}
</script>

<style scoped lang="sass">
.form-item
  position: relative
  width: 100%
  .input
    width: 100%
    box-sizing: border-box
    border: rem(1) solid $cool_A
    border-radius: rem(4)
    padding: rem(10) rem(14)
    text-overflow: ellipsis
    font-family: $main-font
    font-style: normal
    font-weight: 600
    font-size: rem(16)
    line-height: 100%
    color: $black_A
    outline: none
    transition: .5s
    &::-webkit-input-placeholder
      color: $dark-grey
    &:-moz-placeholder
      color: $dark-grey
    &::-moz-placeholder
      color: $dark-grey
    &:-ms-input-placeholder
      color: $dark-grey
    &:focus
      box-shadow: 0 0 rem(5) rgba(51, 51, 51, 0.2), 0 rem(5) rem(15) rgba(51, 51, 51, 0.1)
      border-color: $white
  &--search
    display: flex
    width: auto
    box-sizing: border-box
    +media((min-width: (320: calc(100% - 2rem), 700: 350px), margin: (320: 0 rem(16) rem(16), 700: rem(16), 992: rem(28) 0)))
    &.form-item--icon input
      padding-left: rem(42)
      padding-right: rem(42)
      font-weight: 400
      &::-webkit-search-cancel-button
        display: none
      .search-clear
        display: inline
    &.form-item--icon::before
      content: ''
      display: flex
      width: rem(16)
      height: rem(16)
      position: absolute
      top: 50%
      left: 0
      transform: translate(15px, -50%)
      background-color: $dark-grey
      mask: url(~@/assets/img/icons/dashboard/search.svg)
      mask-repeat: no-repeat
      mask-size: contain
    .search-clear
      display: none
      position: absolute
      top: 0
      right: 0
      z-index: 1
      width: rem(42)
      height: 100%
      padding: 0
      border: none
      background-color: red
      mask: url(~@/assets/img/icons/dashboard/clear.svg) center center no-repeat
      &.visible
        display: block
  &--error
    .input
      border-bottom-color: $red_A
    .error
      position: relative
</style>
