<template>
  <div class="products__filter">
    <div class="container">
      <div class="products__filter-items" v-click-outside="closeOpenedFilter">
        <div
          class="filter-item"
          :class="{ active: currentOpen === `category-${item.id}` }"
          v-for="(item, i) in categories"
          :key="`category-${i}`"
        >
          <div
            class="filter-item__header"
            :class="{
              active: currentOpen === `category-${item.id}`,
              selected: getCheckedCount(item.id) > 0
            }"
            @click="showSubcategories(`category-${item.id}`)"
          >
            <span class="count" v-if="getCheckedCount(item.id) > 0">
              <span class="count__number">
                {{ getCheckedCount(item.id) }}
              </span>
            </span>
            <span class="name">
              {{ item.name }}
            </span>
            <div class="chevron">
              <img src="@/assets/img/icons/filter--select.svg" alt="arrow" />
            </div>
          </div>
          <transition name="slide" mode="in-out">
            <div class="filter-item__body" v-if="currentOpen === `category-${item.id}`">
              <div class="filter-item__body-item" v-for="option in item.options" :key="option.id">
                <div
                  class="item"
                  :class="{
                    'item--active': isItemActive({ category: item.id, option: option.id })
                  }"
                  @click="returnFilteredProducts({ category: item.id, option: option.id })"
                >
                  {{ option.name }}
                </div>
                <!-- <input type="checkbox"
                       class="checkbox"
                       :id="`category-${item.category}-subcategory-${option.id}`"
                       :value="option.id"
                       @change="returnFilteredProducts({category: item.id, option: option.id})">
                <label class="label"
                       :for="`category-${item.category}-subcategory-${option.id}`">

                </label>-->
              </div>
            </div>
          </transition>
        </div>
        <button
          class="clear-filter"
          :class="{ outside: categories.length >= 4 }"
          @click="clearFilters"
          :disabled="multipleFilter.length === 0"
        >
          <svg
            width="40"
            height="32"
            viewBox="0 0 40 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="32" fill="white"></rect>
            <path
              d="M20 18L29 9H11L20 18ZM20 18V25"
              stroke="#333333"
              stroke-width="2"
              stroke-miterlimit="22.9256"
              class="icon"
              stroke-linejoin="round"
            ></path>
            <circle cx="30" cy="8" r="7" fill="white"></circle>
            <path
              d="M27 11L33 5M33 11L27 5"
              stroke="#E30713"
              stroke-width="1.9998"
              stroke-miterlimit="22.9256"
              stroke-linejoin="round"
            ></path>
          </svg>
          <span class="text">{{ $t('message.systems.filterClear') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty, intersectionBy, cloneDeep } from 'lodash'
export default {
  props: {
    products: Array,
    categories: Array,
    normalizedCategories: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data: () => ({
    filterBy: 0,
    currentOpen: null,
    multipleFilter: {}
  }),
  methods: {
    closeOpenedFilter() {
      if (this.currentOpen) {
        this.currentOpen = null
      }
    },
    returnFilteredProducts(val) {
      const { category, option } = val
      if (isEmpty(this.multipleFilter)) {
        this.multipleFilter = cloneDeep(this.normalizedCategories)
      }
      this.multipleFilter[category].includes(option)
        ? (this.multipleFilter[category] = this.multipleFilter[category].filter(o => o !== option))
        : this.multipleFilter[category].push(option)
      this.$emit('returnFilteredProducts', this.multipleFilteredProducts)
    },
    getCheckedCount(category) {
      return this.multipleFilter[category]?.length
    },
    showSubcategories(parent) {
      this.currentOpen === parent ? (this.currentOpen = '') : (this.currentOpen = parent)
    },
    clearFilters() {
      this.multipleFilter = cloneDeep(this.normalizedCategories)
      this.currentOpen = null
      this.$emit('returnFilteredProducts', this.products)
    },
    isItemActive(val) {
      const { category, option } = val
      if (!isEmpty(this.multipleFilter)) {
        return this.multipleFilter[category].includes(option)
      }
      return false
    }
  },
  computed: {
    filteredProductsByCategory() {
      return this.products.filter(p => p.category === +this.filterBy)
    },
    multipleFilteredProductsSuperOld() {
      return this.products.filter(p => {
        return this.multipleFilter.some(f => {
          return p.category === f.category && p.subcategory === f.subcategory
        })
      })
    },
    multipleFilteredProductsOld() {
      return this.products.filter(p => {
        return this.multipleFilter.some(f => {
          return p.filters.includes(f.option)
        })
      })
    },
    multipleFilteredProducts() {
      const isMultipleFilterClear = Object.keys(this.multipleFilter).every(k =>
        isEmpty(this.multipleFilter[k])
      )
      const filteredProducts = intersectionBy(
        ...Object.entries(this.multipleFilter)
          .map(([key, value]) => {
            return this.products.filter(p => {
              return value.includes(p.filters[key])
            })
          })
          .filter(el => !isEmpty(el)),
        'id'
      )
      return isMultipleFilterClear ? this.products : filteredProducts
    }
  }
}
</script>
<style lang="sass" scoped>
.products
  &__filter
    min-height: rem(64)
    background: #fff
    z-index: 1
    &-items
      display: grid
      +media((grid-template-columns: (320: 1fr, 500: repeat(2, 1fr), 960: repeat(4, 1fr))))
      .filter-item
        position: relative
        cursor: pointer
        +media((box-shadow: (320: inset 0 rem(-1) 0 #ECECEC, 960: inset rem(-1) 0 0 #ECECEC)))
        &:first-child
          +media((box-shadow: (320: inset 0 rem(-1) 0 #ECECEC, 960: (inset rem(-1) 0 0 #ECECEC, inset rem(1) 0 0 #ECECEC))))
        &.active
          &:before, &:after
            opacity: 0
        &__header
          display: grid
          +media((grid-template-columns: (320: rem(24) 1fr rem(32) rem(48) rem(8), 960: 1fr 8fr 1fr 2fr)))
          +media((min-height: (320: rem(48), 960: rem(64))))
          .name
            font-style: normal
            font-weight: 500
            font-family: $main-font
            +media((font-size: (320: rem(12), 760: rem(14))))
            +media((line-height: (320: 150%, 960: 140%)))
            grid-column-start: 2
            color: $light-black
            box-sizing: border-box
            display: flex
            flex-direction: row
            align-items: center
          .count
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            &__number
              font-style: normal
              font-weight: 600
              font-size: rem(12)
              line-height: 100%
              display: flex
              align-items: center
              text-align: center
              justify-content: center
              flex-direction: column
              color: $light-black
              background: #ECECEC
              border: rem(1) solid #D6D6D6
              height: rem(18)
              width: rem(18)
              border-radius: 50%
              transition: .5s
          .chevron
            height: 100%
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            grid-column-start: 4
            +media((width: (320: 100%, 960: rem(40))))
            img
              transition: .5s
          &.selected
            +media((grid-template-columns: (320: rem(56) 1fr rem(32) rem(48) rem(8), 960: 2fr 7fr 1fr 2fr)))
          &.active
            .name
              color: $black
            .chevron
              img
                transform: rotate(180deg)
        &__body
          background: #fff
          width: 100%
          z-index: 3
          +media((position: (320: relative, 960: absolute)))
          &:after
            content: ""
            background: transparent
            height: calc(100% + 64px)
            top: rem(-64)
            width: 100%
            position: absolute
            left: 0
            pointer-events: none
            +media((box-shadow: (320: none, 960: $default-box-shadow)))
          &-item
            .item
              cursor: pointer
              transition: .5s
              width: 100%
              min-height: rem(48)
              display: flex
              flex-direction: row
              align-items: center
              padding: rem(6) rem(40) rem(6) rem(20)
              box-sizing: border-box
              position: relative
              font-style: normal
              font-weight: bold
              font-size: rem(12)
              line-height: 150%
              color: $dark-grey
              &:after
                content: url(~@/assets/img/icons/filter--check.svg)
                position: absolute
                top: 0
                right: 0
                pointer-events: none
                width: rem(40)
                height: 100%
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                opacity: 0
              @media (any-hover: hover)
                &:hover
                  background: $red
                  color: #fff
              &--active
                background: $red
                color: #fff
                &:after
                  opacity: 1
      .clear-filter
        cursor: pointer
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        background: none
        box-shadow: none
        border: none
        position: relative
        +media((justify-content: (320: flex-start, 1080: center)))
        +media((grid-column: (320: '1 / 2', 500: '1 / 3', 960: '3 / 4', 1080: unset)))
        +media((width: (320: 100%, 960: auto, 1080: calc((100vw - 960px) / 2), 1200: rem(120))))
        +media((padding: (320: 0 rem(16), 1080: 0)))
        +media((height: (320: rem(48), 960: 100%)))
        top: 0
        outline: none
        .text
          font-family: $main-font
          padding-left: rem(8)
          @extend .fs-12
          color: $light-black
          +media((display: (320: block, 1080: none)))
        svg
          path
            transition: .5s
        &:disabled
          cursor: no-drop
          svg
            .icon
              stroke: $dark-grey
        &.outside
          +media((position: (320: relative, 1080: absolute)))
          +media((height: (320: rem(48), 1080: 100%)))
          +media((grid-column: (320: '1 / 2', 500: '1 / 3', 960: '1 / 5', 1080: unset)))
          +media((right: (320: unset, 1080: calc(((100vw - 960px) / 2) * -1), 1200: rem(-120))))
.slide-enter-active, .slide-leave-active
  transition: .5s
.slide-enter, .slide-leave-to
  opacity: 0
</style>
